<template>
    <div>
        <h2 class="border-bottom pb-2 mb-3"><i class="fa fa-signal"></i> Provider Reporting</h2>

        <div class="report bg-white card p-3" v-if="report">
            <div class="row mb-3">
                <div class="col-12 text-right">
                    <div class="form-inline">
                        <div class="form-group mr-1">
                            <input type="date" v-model="filter.from" class="form-control date" placeholder="From"/>
                        </div>
                        <div class="form-group mr-1">
                            <input type="date" v-model="filter.to" class="form-control date" placeholder="To"/>
                        </div>

                        <button type="button" class="btn text-white mr-1" style="background-color: #383838" @click="load">
                            <i class="fa fa-search" title="Search" v-if="!sLoading"></i>
                            <span
                                class="spinner-border spinner-border-sm"
                                role="status"
                                aria-hidden="true"
                                v-if="sLoading"
                            ></span>

                            Search</button>
                    </div>
                </div>
            </div>
            <div class="row stats mb-4">
                <div v-for="item in report.today" v-bind:key="item._id" class="col-12 col-sm-4">
                    <div class="card border-dark">
                        <div class="card-body text-center">
                            <h4>{{item.total||0}} hits</h4>
                            <h5>{{item.provider}}</h5>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row hours mb-4">
                <!--<div class="col-12">
                    <div class="chart">
                        <line-chart :data="chartdata" area />
                    </div>
                </div>-->
                <!--<div class="col-12 col-sm-2 mb-1" v-for="(hour,x) in report.hours" v-bind:key="x">
                    <div class="card">
                        <div class="card-body text-center">
                            <h4>{{Number(hour||0).toLocaleString()}} hits</h4>
                            <h5>{{x}} hour</h5>
                        </div>
                    </div>
                </div>-->
            </div>
        </div>
    </div>
</template>

<script>
    import axios from 'axios'

    export default {
        name: 'Campaign',
        props:['user'],
        data: function () {
            return {
                action:'',
                campaign:null,
                item: {},
                chartdata:[],
                filter:{},
                report:{today:[{total:0,provider:'Click search for data this is only for viewing'}],},
                providers:[],
                sLoading: false,
                states:[],
                cities:[],
                preloader: false,
                searching: false
            }
        },
        created: function(){
            this.filter.from = this.getDateFormatted(new Date());
            this.filter.to = this.getDateFormatted(new Date());
            this.$root.preloader = false
            // this.load();
            // window.addEventListener("keydown",this.windowListener);
        },
        destroyed: function() {
            window.removeEventListener("keydown",this.windowListener);
        },
        methods:{
            windowListener: function(e){
                if(e.keyCode === 13 && !this.searching){
                    // this.load();
                }
            },
            load: function(){
                this.searching = true;
                //Load the campaign
                var request = {'apikey': this.$root.apikey,filters:{}};

                if(this.filter.from) request.filters.from = this.filter.from;
                if(this.filter.to) request.filters.to = this.filter.to;

                this.sLoading = true;

                return axios.get(`${this.$root.serverUrl}/admin/reports/provider`, {params:request}).then(function(resp){
                    this.report = {today:'',}
                    this.report = (resp.data && resp.data.data)? resp.data.data : null;
                    this.sLoading = false;
                    this.searching = false;
                }.bind(this));
            },
            getDateFormatted: function(d){
                var
                    month = '' + (d.getMonth() + 1),
                    day = '' + d.getDate(),
                    year = d.getFullYear();

                if (month.length < 2)
                    month = '0' + month;
                if (day.length < 2)
                    day = '0' + day;
                return [year, month, day].join('-');
            },
        }
    }
</script>

<style scoped>
    .chart {
        width: 100%;
        height: 300px;
    }
</style>
